<template>
  <div>
    <MyBeeSearchBar @goodPaging="goodPaging" @changePage="changePage" @devSearch="devSearch"
                    :curr-page.sync="pagingParams.currPage"
                    :per-page.sync="pagingParams.perPage"
                    :per-page-list="perPageList"
                    :pages="pages"
                    :search-bar-place-holder="searchBarPlaceHolder"
                    :keyword.sync="pagingParams.keyword"></MyBeeSearchBar>
    <BeeGridTable
        ref="bee"
        border
        stripe
        :showFilter="false"
        :columns="columns"
        :loading="isLoading"
        :data="rows"
        :showPager="false"
        :maxHeight="gridCardHeight"
        highlightRow
        no-data-text="データがありません。"
        no-filtered-data-text=""
        :context="context"
        :span-method="customSpan"
        :showVerticalScrollBar="false"
        :showHorizontalScrollBar="false">
      <!-- HEADER ソート可能にするために 必要-->
      <template slot-scope="{ column,index }" slot="idHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="nameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="addressHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="createdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="updatedHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>

      <!-- cell -->
      <template slot-scope="{ row,index }" slot="id"><div class="text-left">{{row.id}}</div></template>
      <template slot-scope="{ row,index }" slot="name"><div class="text-left">{{row.name}}</div></template>
      <template slot-scope="{ row,index }" slot="address"><div class="text-left">{{row.address}}</div></template>
      <template slot-scope="{ row,index }" slot="created"><div class="text-left">{{row.created}}</div></template>
      <template slot-scope="{ row,index }" slot="updated"><div class="text-left">{{row.updated}}</div></template>


      <template slot-scope="{ row,index }" slot="after">
        <div class="float-center">
          <CButton color="success py-0" size="sm" class="mr-1" @click="setHospital(row)" >指定</CButton>
        </div>
      </template>
    </BeeGridTable>
  </div>

</template>

<script>

import BeeMix from '@/views/aprod/js/mixin/beeUtils'
import MyBeeHeader from "@/component/parts/MyBeeHeader";
import MyBeeSearchBar from "@/component/parts/MyBeeSearchBar";
export default {
      name: 'Hospital',
      components:{
        MyBeeHeader, MyBeeSearchBar
      },
      mixins: [BeeMix],
      props: {
        isShow: { default: false, type: Boolean },
      },
      mounted() {
        this.goodPaging();
      },
      data: () => {
        return {
          columns: [
            {title: 'Id', key: 'id',slot:'id',headSlot:'idHead'},
            {title: '医療機関名', key: 'name',slot:'name',headSlot:'nameHead'},
            {title: '住所', key: 'address',slot:'address',headSlot:'addressHead'},
            {title: '登録日時', key: 'created',slot:'created',headSlot:'createdHead'},
            {title: '更新日時', key: 'updated',slot:'updated',headSlot:'updatedHead'},
            {title: ' ', key: 'after',slot:'after',headSlot:'afterHead'}
          ],
          // isCollapsed:true
          searchBarPlaceHolder:"医療機関名で検索"
        };
      },
      methods: {
        // addDoctor:function(row){
        //   this.$emit("addDoctor",row);
        // },
        // filterDoctor:function(row){
        //   this.$emit("filterDoctor",row);
        // },
        setHospital:function(row){
          this.$emit("setHospital",row);
        },
        goodPaging: function (isPageChange) {
          if (!isPageChange) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する
          this.isLoading = true;
          this.axios.post('/api/hospital/searchPaging', this.pagingParams, {headers: {'Content-Type': 'application/json'}}).then(response => {
            this.totalRecords = response.data.result.totalElements;
            this.rows = response.data.result.content;
            //ページ件数
            this.pages = response.data.result.totalPages;
            this.topLeft();
          }).catch(error => console.log(error)
          ).finally(() => this.isLoading = false);
        }
      },
      watch:{
        isShow:function(){
          if(this.isShow){
            this.goodPaging();
          }
        }
      }
    }

</script>
